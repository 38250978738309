import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, Grid } from "@mui/material";
import axios from "axios";

export default function SingerSelector({
  onSingerChange,
  onOctaveChange,
  isSongUploaded,
}) {
  const [selectedSinger, setSelectedSinger] = useState("");
  const [selectedOctave, setSelectedOctave] = useState(0);
  const [singers, setSingers] = useState([]);

  useEffect(() => {
    axios
      .get("https://ba.fabiancannaheim.com/ddsp/models/")
      .then((response) => {
        const models = response.data.model_names;
        setSingers(["Original", ...models]);
      })
      .catch((error) => {
        console.error("Error fetching models:", error);
      });
  }, []);

  const handleSingerChange = (event) => {
    const singer = event.target.value;
    setSelectedSinger(singer);
    onSingerChange && onSingerChange(singer);
  };

  const handleOctaveChange = (event) => {
    const octave = event.target.value;
    setSelectedOctave(octave);
    onOctaveChange(octave);
  };

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item sx={{ marginRight: 1 }}>
        <FormControl
          sx={{
            minWidth: 60,
            height: 36, // Adjust height as needed
            "& .MuiInputLabel-root": {
              fontSize: "0.9rem",
              transform:
                selectedOctave !== ""
                  ? "translate(14px, -6px) scale(0.75)"
                  : "translate(14px, 10px) scale(1)", // Adjust label position
              color: "black",
            },
            "& .MuiSelect-root": {
              fontSize: "0.9rem",
              padding: "6px 14px", // Adjust padding for smaller height
              height: "36px", // Match the height of FormControl
            },
          }}
        >
          <InputLabel id="octave-select-label">Octave</InputLabel>
          <Select
            labelId="octave-select-label"
            id="octave-select"
            value={selectedOctave}
            label="Shift"
            onChange={handleOctaveChange}
            sx={{
              fontSize: "0.9rem",
              padding: "4px 8px", // Adjust padding for smaller height
              height: "36px", // Match the height of FormControl
              color: "white",
              width: "60px", // Fix the width
            }}
          >
            {[2, 1, 0, -1, -2].map((octave) => (
              <MenuItem key={octave} value={octave}>
                {octave}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sx={{ marginRight: 0 }}>
        <FormControl
          sx={{
            minWidth: 60,
            height: 36, // Adjust height as needed
            "& .MuiInputLabel-root": {
              fontSize: "0.9rem",
              transform:
                selectedSinger !== ""
                  ? "translate(14px, -6px) scale(0.75)"
                  : "translate(14px, 10px) scale(1)", // Adjust label position
              color: "white",
            },
            "& .MuiSelect-root": {
              fontSize: "0.9rem",
              padding: "6px 14px", // Adjust padding for smaller height
              height: "36px", // Match the height of FormControl
            },
            color: "#080808",
          }}
        >
          <InputLabel id="singer-select-label" style={{ color: "#0F0F0F" }}>
            Swap
          </InputLabel>
          <Select
            labelId="singer-select-label"
            id="singer-select"
            value={selectedSinger}
            label="Swap"
            onChange={handleSingerChange}
            sx={{
              fontSize: "0.9rem",
              padding: "4px 8px", // Adjust padding for smaller height
              height: "36px", // Match the height of FormControl
              color: "white",
              width: "80px", // Fix the width
            }}
          >
            {singers.map((singer) => (
              <MenuItem key={singer} value={singer}>
                {singer.charAt(0).toUpperCase() + singer.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
