import React, { useState, useRef, useEffect } from "react";
import { IconButton, Slider, Typography, Grid } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import AudioUploader from "./AudioUploader";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import JSZip from "jszip";
import * as tf from "@tensorflow/tfjs";

// Adjusting some more specific flags to see if it helps resolve the shader issues
tf.env().set("WEBGL_PACK", true); // You may try toggling this again
tf.env().set("WEBGL_PACK_DEPTHWISECONV", true);
tf.env().set("WEBGL_CONV_IM2COL", true);
tf.env().set("WEBGL_MAX_TEXTURE_SIZE", 16384); // Depending on GPU capacity
tf.env().set("WEBGL_LAZILY_UNPACK", true);
tf.env().set("WEBGL_DISJOINT_QUERY_TIMER_EXTENSION_VERSION", 2); // If supported by your browser

const AudioPlayer = ({ onSongUploaded, selectedSinger, octaveShift }) => {
  const [baseFileName, setBaseFileName] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [loopFlag, setLoopFlag] = useState(false);
  const [firstFlag, setFirstFlag] = useState(false);
  const [volumes, setVolumes] = useState([1, 1, 1, 1]); // Initialize volumes for 4 stems
  const numberOfStems = 4; // You can change this based on how many stems you have
  const gainNodes = useRef([]); // Ref to store gain nodes
  const sourceNodes = useRef([]); // Ref to store source nodes
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [chunks, setChunks] = useState([]);
  const [overlapChunks, setOverlapChunks] = useState([]);
  const [lastTime, setLastTime] = useState(new Date());
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timeBuffer, setTimeBuffer] = useState([1000, 1000]);
  const [delay, setDelay] = useState(980);

  const [origChunks, setOrigChunks] = useState([]);
  const [stems, setStems] = useState([]); // State to hold the audio buffers for each stem
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const recorderRef = useRef(null);

  const stemNames = ["Vocals", "Drums", "Bass", "Accompaniment"];
  const getSliderColor = (index) => {
    const colors = ["#FF8300", "red", "purple", "blue"]; // Example colors: Red, Blue, Purple, Green
    return colors[index % colors.length]; // Repeat colors if there are more sliders than colors
  };

  const audioCtxRef = useRef(null);

  if (!audioCtxRef.current || audioCtxRef.current.state === "closed") {
    audioCtxRef.current = new AudioContext({ sampleRate: 48000 });
  }

  //*******************************UPLOADING AND PROCESSING FILE*********************
  const handleFileUpload = async (file) => {
    const newName = file.name.replace(/\.(wav|mp3)$/, "");
    setBaseFileName(newName);

    setIsLoading(true); // Start loading
    const formData = new FormData();
    formData.append("audio", file);

    try {
      const response = await axios.post(
        "https://ba.fabiancannaheim.com/api/spleeter/4stems",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer", // Ensure you receive a blob response
        }
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Assuming the response is a blob of a ZIP file
      const blob = new Blob([response.data], { type: "application/zip" });
      const zip = new JSZip();
      const zipContents = await zip.loadAsync(blob);
      const files = Object.keys(zipContents.files).filter(
        (fileName) => fileName.endsWith(".wav") // Assuming you want .wav files, adjust if necessary
      );

      // Prepare to load and decode each stem
      const stems = [];
      for (const fileName of files) {
        const fileData = await zipContents.files[fileName].async("blob");
        const arrayBuffer = await fileData.arrayBuffer();
        const audioBuffer = await audioCtxRef.current.decodeAudioData(
          arrayBuffer
        );
        stems.push(audioBuffer);
      }

      setStems(stems); // Assuming you have a setStems function to update your state
      setIsLoading(false); // Stop loading
      onSongUploaded(true); // Notify App that a song has been uploaded
    } catch (error) {
      console.error("Error sending file:", error);
      setIsLoading(false); // Ensure loading is stopped on error
      onSongUploaded(false); // Notify App that upload failed
    }
  };

  //*******************************SPLITTING INTO CHUNKS*********************
  useEffect(() => {
    if (stems.length > 0 && stems[0] !== null) {
      // Temporary array to hold chunks for each stem
      const allChunks = stems.map((stem) => splitBufferIntoChunks(stem, 1));
      setChunks(allChunks);
      setOrigChunks(allChunks);

      // Compress only the first stem
      compressStem(stems[0]).then((compressedStem) => {
        // Split the compressed first stem into overlapping chunks
        const overlappingChunks =
          splitBufferIntoOverlappingChunks(compressedStem);
        setOverlapChunks(overlappingChunks);

        setIsLoading(false); // Stop loading
      });
    }
  }, [stems]);

  const compressStem = async (buffer) => {
    const offlineCtx = new OfflineAudioContext(
      buffer.numberOfChannels,
      buffer.length,
      buffer.sampleRate
    );
    const source = offlineCtx.createBufferSource();
    source.buffer = buffer;

    const compressor = offlineCtx.createDynamicsCompressor();
    compressor.threshold.setValueAtTime(-20, offlineCtx.currentTime); // Adjust threshold
    compressor.knee.setValueAtTime(40, offlineCtx.currentTime); // Adjust knee
    compressor.ratio.setValueAtTime(10, offlineCtx.currentTime); // Adjust ratio
    compressor.attack.setValueAtTime(0, offlineCtx.currentTime); // Adjust attack
    compressor.release.setValueAtTime(0.25, offlineCtx.currentTime); // Adjust release

    source.connect(compressor);
    compressor.connect(offlineCtx.destination);
    source.start();

    const renderedBuffer = await offlineCtx.startRendering();
    return renderedBuffer;
  };

  useEffect(() => {
    if (selectedSinger === "Original") {
      // Create a deep copy of the original chunks
      const deepCopyChunks = origChunks.map((chunkSet) =>
        chunkSet.map((chunk) => {
          const newChunk = audioCtxRef.current.createBuffer(
            chunk.numberOfChannels,
            chunk.length,
            chunk.sampleRate
          );
          for (
            let channel = 0;
            channel < newChunk.numberOfChannels;
            channel++
          ) {
            newChunk.copyToChannel(chunk.getChannelData(channel), channel);
          }
          return newChunk;
        })
      );

      // Set the chunks state to the deep copy of the original chunks
      setChunks(deepCopyChunks);
    }
  }, [selectedSinger]);

  useEffect(() => {
    const processInitialChunk = async () => {
      try {
        //if (currentChunkIndex == 0) {
        const processedChunk = await processChunk(overlapChunks[0]);
        if (processedChunk) {
          setChunks((prevChunks) => {
            const newChunks = [...prevChunks];
            newChunks[0][0] = processedChunk; // Process the first chunk
            return newChunks;
          });
          //}
        }
      } catch (error) {
        console.error("Error processing chunk:", error);
      }
    };

    if (selectedSinger != "Original" && selectedSinger != "") {
      setFirstFlag(true);
      processInitialChunk();
    }
  }, [selectedSinger]); // Adding all relevant dependencies

  const splitBufferIntoChunks = (buffer, chunkDurationSec) => {
    const sampleRate = buffer.sampleRate;
    const chunkLength = sampleRate * chunkDurationSec;
    const numberOfChunks = Math.ceil(buffer.duration / chunkDurationSec);
    const chunks = [];

    for (let i = 0; i < numberOfChunks; i++) {
      const startOffset = i * chunkLength;
      const endOffset = Math.min((i + 1) * chunkLength, buffer.length);

      const chunkBuffer = audioCtxRef.current.createBuffer(
        buffer.numberOfChannels,
        endOffset - startOffset,
        sampleRate
      );

      for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
        const originalData = buffer.getChannelData(channel);
        const chunkData = chunkBuffer.getChannelData(channel);
        chunkData.set(originalData.subarray(startOffset, endOffset));
      }
      chunks.push(chunkBuffer);
    }
    return chunks;
  };

  const splitBufferIntoOverlappingChunks = (buffer) => {
    const sampleRate = buffer.sampleRate;
    const chunkLength = 96000; // Length of each chunk
    const overlapLength = 48000; // Length of overlap
    const stepLength = chunkLength - overlapLength; // Step length for the next chunk
    const numberOfChunks =
      Math.ceil((buffer.length - overlapLength) / stepLength) + 1; // Increase the size of the array by 1
    const chunks = [];

    for (let i = 0; i < numberOfChunks; i++) {
      let startOffset, endOffset;
      if (i < numberOfChunks - 1) {
        startOffset = i * stepLength;
        endOffset = Math.min(startOffset + chunkLength, buffer.length);
      } else {
        // Handle the last chunk separately
        startOffset = buffer.length - 48; // 48 samples from the end
        endOffset = buffer.length;
      }

      const chunkBuffer = audioCtxRef.current.createBuffer(
        buffer.numberOfChannels,
        endOffset - startOffset,
        sampleRate
      );

      for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
        const originalData = buffer.getChannelData(channel);
        const chunkData = chunkBuffer.getChannelData(channel);
        chunkData.set(originalData.subarray(startOffset, endOffset));
      }
      chunks.push(chunkBuffer);
    }
    return chunks;
  };

  //*******************************STOP*********************
  const handleStop = () => {
    // Stop and disconnect all sources
    sourceNodes.current.forEach((source, index) => {
      if (source) {
        source.stop(); // Stop the source
        source.disconnect(); // Disconnect it from the audio context
        sourceNodes.current[index] = null; // Clear the source from the array
      }
    });
    sourceNodes.current = []; // Clear the entire array to reset

    setCurrentChunkIndex(0); // Reset the chunk index to the start
    setFirstFlag(true);
    setIsPlaying(false); // Update the playing state to false
  };

  //*******************************PLAY/PAUSE*********************
  const handlePlayPause = () => {
    if (!isPlaying && currentChunkIndex > 0) {
      setCurrentChunkIndex(currentChunkIndex - 1);
    }
    setIsPlaying(!isPlaying);
    setLoopFlag(!loopFlag);
  };

  useEffect(() => {
    if (isPlaying) {
      playChunkAtIndex(currentChunkIndex);
    } else {
      sourceNodes.current.forEach((source, index) => {
        if (source) {
          source.disconnect();
          source.stop(); // Stop each source
          sourceNodes.current[index] = null; // Clear the source references
        }
      });
      sourceNodes.current = []; // Optionally clear the entire array after looping
    }
  }, [loopFlag]); // Reacts to changes in isPlaying and loopFlag

  //*******************************PRINT TIME*********************
  const calcElapsedTimeAndSetBuffer = () => {
    const currentTime = new Date();
    const elapsedTime = currentTime - lastTime; // Elapsed time in milliseconds
    setLastTime(currentTime);
    setElapsedTime(elapsedTime); // Set elapsed time in milliseconds
    console.log(`Elapsed time: ${elapsedTime} milliseconds`);

    // Check if the elapsed time is within the specified range
    if (elapsedTime > 900 && elapsedTime < 1100) {
      // Update the buffer synchronously
      const updatedBuffer = [elapsedTime, ...timeBuffer];
      if (updatedBuffer.length > 2) {
        updatedBuffer.pop();
      }

      // Calculate the average of the times in the buffer
      const averageTime =
        updatedBuffer.reduce((sum, time) => sum + time, 0) /
        updatedBuffer.length;
      const delta = 1000 - averageTime;

      // Update the state with the new buffer
      setTimeBuffer(updatedBuffer);

      return delta;
    }

    return 0; // Or return another value indicating that the result was not calculated
  };

  //*******************************PLAY AT INDEX*********************

  const playChunkAtIndex = async (index) => {
    setIsPlaying(true);
    const playbackSessionId = Date.now();

    for (let stemIndex = 0; stemIndex < numberOfStems; stemIndex++) {
      const buffer = chunks[stemIndex][index];
      const source = audioCtxRef.current.createBufferSource();
      source.buffer = buffer;
      source.connect(gainNodes.current[stemIndex]);
      gainNodes.current[stemIndex].connect(audioCtxRef.current.destination);
      source.start();
      sourceNodes.current.push(source);
    }

    let delta = calcElapsedTimeAndSetBuffer() || 0;

    let delayTimer = delay + delta;

    let processedChunk = null;
    setCurrentChunkIndex(currentChunkIndex + 1);
    setFirstFlag(false);

    setTimeout(() => {
      if (index < chunks[0].length && isPlaying) {
        if (
          index + 1 < overlapChunks[0].length &&
          selectedSinger != "Original" &&
          selectedSinger != ""
        ) {
          if (processedChunk) {
            setChunks((prevChunks) => {
              const newChunks = [...prevChunks];
              newChunks[0][index + 1] = processedChunk;
              return newChunks;
            });
          }
        }
        setDelay(delayTimer);
        setLoopFlag(!loopFlag);
      }
    }, delayTimer);

    try {
      if (
        index < chunks[0].length &&
        isPlaying &&
        selectedSinger != "Original" &&
        selectedSinger != ""
      ) {
        processedChunk = await processChunk(overlapChunks[index + 1]);
      }
    } catch (error) {
      console.error("Error processing chunk:", error);
    }

    sourceNodes.current.forEach((source) => {
      source.onended = () => {
        if (source.playbackSessionId === playbackSessionId) {
          sourceNodes.current.forEach((src) => src.stop());
          sourceNodes.current = [];
        }
      };
    });
  };

  async function processChunk(buffer) {
    try {
      const processedAudioBuffer = await convertToMono(buffer);
      const monoSamples = processedAudioBuffer.getChannelData(0);

      const response = await fetch(
        "https://ba.fabiancannaheim.com/ddsp/process/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: Array.from(monoSamples),
            model_name: selectedSinger,
            octave_shift: octaveShift,
            first_chunk_flag: firstFlag,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const outputArray = responseData.output_data[0];
        const audioCtx = new (window.AudioContext ||
          window.webkitAudioContext)();
        const newBuffer = audioCtx.createBuffer(
          1,
          outputArray.length,
          audioCtx.sampleRate
        );
        setFirstFlag(false);
        newBuffer.copyToChannel(Float32Array.from(outputArray), 0);
        return newBuffer;
      } else {
        console.error("Error response from server:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error during chunk processing:", error);
      return null;
    }
  }

  // ConvertToMono function which creates an AudioBuffer that is mono
  async function convertToMono(audioBuffer) {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;
    const sampleRate = audioBuffer.sampleRate;
    let monoBuffer = new Float32Array(length);

    for (let channel = 0; channel < numberOfChannels; channel++) {
      let channelData = audioBuffer.getChannelData(channel);
      for (let i = 0; i < length; i++) {
        monoBuffer[i] += channelData[i] / numberOfChannels;
      }
    }

    const offlineCtx = new OfflineAudioContext(1, length, sampleRate);
    const newBuffer = offlineCtx.createBuffer(1, length, sampleRate);
    newBuffer.copyToChannel(monoBuffer, 0);
    return newBuffer; // No need to startRendering since no up/downsampling is required
  }

  // Remember to update your initialization of sourceNodes and gainNodes if not already defined:
  useEffect(() => {
    sourceNodes.current = [];
    gainNodes.current = Array.from({ length: numberOfStems }, () =>
      audioCtxRef.current.createGain()
    );
  }, []);

  //*******************************RECORDER*********************

  // Utility function to create zero-filled audio buffers
  const createSilentAudioBuffer = (length, sampleRate) => {
    const channels = 1; // Mono buffer
    const buffer = audioCtxRef.current.createBuffer(
      channels,
      length,
      sampleRate
    );
    for (let channel = 0; channel < channels; channel++) {
      const nowBuffering = buffer.getChannelData(channel);
      nowBuffering.fill(0); // Fill the buffer with zeros
    }
    return buffer;
  };

  // Start recording
  const startRecording = async () => {
    // Stop and reset everything first
    // Assume stop() function handles stopping any currently playing or recording activities
    handleStop(); // This needs to be defined according to your existing stop logic
    setStems([]); // Clear any existing stems data

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorderRef.current = new MediaRecorder(stream);

      const recordedChunks = [];
      recorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };

      recorderRef.current.onstop = async () => {
        const blob = new Blob(recordedChunks, { type: "audio/wav" });
        const audioBuffer = await blob.arrayBuffer();
        const decodedAudio = await audioCtxRef.current.decodeAudioData(
          audioBuffer
        );

        const silentBuffers = [];
        for (let i = 1; i < 4; i++) {
          // Assuming you have 4 stems in total
          silentBuffers.push(
            createSilentAudioBuffer(
              decodedAudio.length,
              decodedAudio.sampleRate
            )
          );
        }

        setStems([decodedAudio, ...silentBuffers]); // First stem has recorded audio, others are silent
      };

      recorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Failed to start recording:", error);
      setIsRecording(false);
    }
  };

  // Stop recording
  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stop(); // Stops the recording
      recorderRef.current.stream.getTracks().forEach((track) => track.stop()); // Stop each track on the stream
      setIsRecording(false);
    }
  };

  //*******************************SLIDER*********************
  const handleSliderChange = (event, newValue) => {
    if (stems.length > 0 && !isLoading) {
      handleStop(); // This stops the current playback and resets if necessary
      setCurrentChunkIndex(newValue); // Updates the chunk index without starting playback
    }
  };

  const handleSliderCommit = (event, newValue) => {
    if (stems.length > 0 && !isLoading) {
      setIsPlaying(true);
    }
  };

  //*******************************VOLUMES*********************
  useEffect(() => {
    gainNodes.current = Array.from({ length: numberOfStems }, () => {
      const gainNode = audioCtxRef.current.createGain();
      gainNode.connect(audioCtxRef.current.destination);
      return gainNode;
    });
  }, []);

  const handleVolumeChange = (index, newValue) => {
    const newVolumes = [...volumes];
    newVolumes[index] = newValue;
    setVolumes(newVolumes);
    gainNodes.current[index].gain.value = newValue; // Update the gain node value
  };

  return (
    <div
      id="audioPlayer"
      style={{
        padding: "10px 7px",
        paddingBottom: "30px",
        border: "3px solid rgba(0, 0, 0, 0.5)", // Semi-transparent black border
        borderRadius: "15px", // Rounded corners
        maxWidth: "800px",
        margin: "0 auto",
        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.1)", // Soft shadow for depth
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Set background color to white with opacity
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "fixed", // Overlay and fixed to the viewport
            top: "50%", // Position to 50% of the viewport height
            left: "50%", // Position to 50% of the viewport width
            transform: "translate(-50%, -50%)", // Offset the element to the center of the viewport
            width: "220px", // Specific width for the element
            height: "150px", // Specific height for the element
            backgroundColor: "#A30000", // Dark red background with 0.5 opacity

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px", // Rounded borders
            zIndex: 9999,
            fontSize: "15px",
            fontWeight: "550",
            textTransform: "none",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {" "}
            {/* Center the text and spinner vertically and horizontally */}
            <CircularProgress />
            <Typography variant="h4" style={{ marginTop: 20, color: "black" }}>
              Loading your stems...
            </Typography>
          </div>
        </div>
      )}
      <AudioUploader
        onFileUpload={handleFileUpload}
        style={{ margin: "0 auto", maxWidth: "100%", padding: "0 16px" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: 16,
          }}
        >
          <IconButton
            onClick={handlePlayPause}
            style={{ color: "#080808", opacity: stems.length > 0 ? 1 : 0.5 }}
            disabled={stems.length === 0 || isLoading}
            aria-label={isPlaying ? "pause" : "play"}
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton
            onClick={handleStop}
            style={{ color: "#080808", opacity: stems.length > 0 ? 1 : 0.5 }}
            disabled={stems.length === 0 || isLoading}
            aria-label="stop"
          >
            <StopIcon />
          </IconButton>

          <IconButton
            onClick={() => (isRecording ? stopRecording() : startRecording())}
            style={{ color: "#080808", opacity: 1 }}
            aria-label={isRecording ? "stop recording" : "start recording"}
          >
            {isRecording ? <StopIcon /> : <MicIcon />}
          </IconButton>
        </div>

        <Slider
          min={0}
          max={chunks.length > 0 && chunks[0] ? chunks[0].length - 1 : 0}
          value={currentChunkIndex - 1}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderCommit}
          aria-labelledby="chunk-slider"
          sx={{ width: "90%", maxWidth: "800px" }}
          color="antrazit"
          disabled={stems.length === 0 || isLoading} // Disable the slider when stems are not loaded or still loading
        />

        {stems.length > 0 && (
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            style={{
              color: "#0f0f0f",
              fontSize: "15px",
              fontWeight: "550",
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            }}
          >
            {`Chunk ${currentChunkIndex} of ${
              chunks.length > 0 && chunks[0] ? chunks[0].length - 1 : 0
            }`}
          </Typography>
        )}
      </div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ marginTop: 20 }}
      >
        {stemNames.map((name, index) => (
          <Grid
            item
            xs={12}
            key={index}
            style={{
              color: "#0f0f0f",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{
                color: "#080808",
                fontSize: "15px",
                fontWeight: "550",
                borderRadius: "10px",
                textTransform: "none",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              }}
            >
              {name}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "90%",
                maxWidth: "800px",
              }}
            >
              <IconButton
                onClick={() =>
                  handleVolumeChange(index, Math.max(0, volumes[index] - 0.1))}
                  aria-label={`decrease volume of ${name}`}
              >
                <VolumeDownIcon />
              </IconButton>
              <Slider
                orientation="horizontal"
                value={volumes[index]}
                min={0}
                max={1}
                step={0.01}
                onChange={(e, val) => handleVolumeChange(index, val)}
                aria-labelledby={`horizontal-slider-${index}`}
                sx={{ flexGrow: 1, color: getSliderColor(index) }} // Set the color dynamically
              />
              <IconButton
                onClick={() =>
                  handleVolumeChange(index, Math.min(1, volumes[index] + 0.1))}
                  aria-label={`increase volume of ${name}`}
              >
                <VolumeUpIcon />
              </IconButton>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AudioPlayer;
